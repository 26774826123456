const translations = {
  english:{
    translation: {
      events:{
        USER_LOGIN_SUCCESS:'User login',
        USER_LOGOUT:'User logout',
        VALIDATION_ACK:'Validation done',
        VALIDATION_ACK_EMAIL:'Validation done (email)',
        VALIDATION_ACK_EMAIL_PRIMARY:'Validation done (primary email)',
        VALIDATION_ACK_EMAIL_SECONDARY:'Validation done (secondary email)',
        VALIDATION_EMAIL_SENT:'Validation requested (email)',
        VALIDATION_EMAIL_PRIMARY_SENT:'Validation requested (primary email)',
        VALIDATION_EMAIL_SECONDARY_SENT:'Validation requested (secondary email)',
        VALIDATION_MOBILE_SENT:'Validation requested (mobile)',
        VALIDATION_MOBILE_PRIMARY_SENT:'Validation requested (primary mobile)',
        VALIDATION_MOBILE_SECONDARY_SENT:'Validation requested (secondary mobile)',
        VALIDATION_MESSAGE_SENT:'Validation requested',
        TICKET_DRAFT_CREATED:'Ticket (draft) created',
        TICKET_DRAFT_UPDATED:'Ticket (draft) updated',
      }
    }
  },
  french:{
    translation: {
      events:{
        USER_LOGIN_SUCCESS:'Connexion utilisateur',
        USER_LOGOUT:'Déconnexion utilisateur',
        VALIDATION_ACK:'Validation effectuée',
        VALIDATION_ACK_EMAIL:'Validation effectuée (email)',
        VALIDATION_ACK_EMAIL_PRIMARY:'Validation effectuée (email primaire)',
        VALIDATION_ACK_EMAIL_SECONDARY:'Validation effectuée (email secondaire)',
        VALIDATION_EMAIL_SENT:'Validation demandée (email)',
        VALIDATION_EMAIL_PRIMARY_SENT:'Validation demandée (email primaire)',
        VALIDATION_EMAIL_SECONDARY_SENT:'Validation demandée (email secondaire)',
        VALIDATION_MOBILE_SENT:'Validation demandée (mobile)',
        VALIDATION_MOBILE_PRIMARY_SENT:'Validation demandée (mobile primaire)',
        VALIDATION_MOBILE_SECONDARY_SENT:'Validation demandée (mobile secondaire)',
        VALIDATION_MESSAGE_SENT:'Validation demandée',
        TICKET_DRAFT_CREATED:'Ticket (brouillon) créé',
        TICKET_DRAFT_UPDATED:'Ticket (brouillon) mise à jour',
      }
    },
  }
}

const list = {
  USER_LOGIN_SUCCESS:                 { icon:'login', color:'success', who:'${src}' },
  USER_LOGOUT:                        { icon:'logout', color:'success', who:'${src}' },
  VALIDATION_ACK:                     { icon:'verified', color:'success', who:'${archive.name} (${src})' },
  VALIDATION_ACK_EMAIL:               { icon:'mark_email_read', color:'success', who:'${archive.name} (${src})' },
  VALIDATION_ACK_EMAIL_PRIMARY:       { icon:'mark_email_read', color:'success', who:'${archive.name} (${src})' },
  VALIDATION_ACK_EMAIL_SECONDARY:     { icon:'mark_email_read', color:'success', who:'${archive.name} (${src})' },

  VALIDATION_MESSAGE_SENT:            { icon:'verified', color:'primary', who:'${archive.name} (${dst})' },
  
  VALIDATION_EMAIL_SENT:              { icon:'email', color:'primary', who:'${archive.name} (${dst})' },
  VALIDATION_EMAIL_PRIMARY_SENT:      { icon:'email', color:'primary', who:'${archive.name} (${dst})' },
  VALIDATION_EMAIL_SECONDARY_SENT:    { icon:'email', color:'primary', who:'${archive.name} (${dst})' },

  VALIDATION_MOBILE_SENT:             { icon:'smartphone', color:'primary', who:'${archive.name} (${dst})' },
  VALIDATION_MOBILE_PRIMARY_SENT:     { icon:'smartphone', color:'primary', who:'${archive.name} (${dst})' },
  VALIDATION_MOBILE_SECONDARY_SENT:   { icon:'smartphone', color:'primary', who:'${archive.name} (${dst})' },

  TICKET_DRAFT_CREATED:               { icon:'edit_square', color:'primary', who:'${archive.name}, ticket hash ${ticketHash}' },
  TICKET_DRAFT_UPDATED:               { icon:'edit_square', color:'primary', who:'${archive.name}, ticket hash ${ticketHash}' },
}

function getColor(c) {
  const colorName = c?.includes('--') ? c : c + '-700';
  return `var(--sl-color-${colorName})`;
}

const events = {};
Object.keys(list).sort().forEach(key => {
  events[key] = list[key];
  events[key].iconColor = getColor(list[key].color);
});


export default {
  events,
  translations,
  apiEndpoint:'private/admin/system/events',
  urlVar:'ide'
};